import { FC, useContext } from 'react';
import {  FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import FormHeader from '../../../FormHeader';
import { AddressLookup } from './AddressLookup';
import { AreaLocationLookup } from './AreaLocationLookup';
import { GPSLookup } from './GPSLookup';
import { FormContext } from '../../FormContext';

type Step4Props = {
};

export const Step4Summary: FC<Step4Props> = () => {
  const { formData } = useContext(FormContext);
  const disabledInput = { readOnly: true }
  if (!formData || !formData.location || !formData.provider) return <></>
  return (<>
    <Stack justifyContent={'center'} alignItems="center" direction="row" gap="5px">
      <TextField
        focused
        fullWidth
        margin="normal"
        variant="standard"
        label="Adresse"
        value={formData.location.adressetekst}
        InputProps={disabledInput} />
      <TextField
        focused
        fullWidth
        margin="normal"
        variant="standard"
        InputProps={disabledInput}
        label="Postnummer"
        value={formData.location.postnummer} />
      <TextField
        focused
        fullWidth
        margin="normal"
        variant="standard"
        label="Poststed"
        value={formData.location.poststed}
        InputProps={disabledInput} />
    </Stack>
    <Stack justifyContent='center' alignItems="center" direction="row" gap="5px">
      <TextField
        focused
        fullWidth
        margin="normal"
        variant="standard"
        label="Kommune"
        value={formData.location.kommunenavn}
        InputProps={disabledInput} />
      <TextField
        focused
        fullWidth
        margin="normal"
        variant="standard"
        label="Gårdsnummer"
        value={formData.location.gardsnummer}
        InputProps={disabledInput} />
      <TextField
        focused
        fullWidth
        margin="normal"
        variant="standard"
        label="Bruksnummer"
        value={formData.location.bruksnummer}
        InputProps={disabledInput} />
    </Stack>
    <Stack justifyContent={'center'} alignItems="center" direction="row" gap="5px">
      <TextField
        focused
        fullWidth
        margin="normal"
        variant="standard"
        label="Lengdegrad (Østlig)"
        value={parseFloat(formData.location.representasjonspunkt.lon.toPrecision(8))}
        InputProps={disabledInput} />
      <TextField
        focused
        fullWidth
        margin="normal"
        variant="standard"
        label="Breddegrad (Nordlig)"
        value={parseFloat(formData.location.representasjonspunkt.lat.toPrecision(8))}
        InputProps={disabledInput} />
    </Stack>
    <TextField
      focused
      fullWidth
      margin="normal"
      variant="standard"
      label="Nettselskap"
      value={formData.provider.name}
      InputProps={disabledInput} />
  </>)
}

export const Step4: FC<Step4Props> = () => {
  const { handleFormChange, locationType, onChangeLocationType, setLocation, selectedLocationOption } = useContext(FormContext);
  return (
    <>
      <FormHeader header='Lokasjon' subHeader='Du kan legge inn gårds- og bruksnummer, gateadrese eller GPS-koordinater.'></FormHeader>
      <Stack >
        <Typography variant="body1" color={"gray"}>
          Velg type plassering
        </Typography>
        <FormControl style={{ paddingBottom: 25 }}>
          <RadioGroup
            name="locationType"
            onChange={handleFormChange}>
            <FormControlLabel checked={locationType === 1} onClick={() => onChangeLocationType(1)} className='radio-form-location' value="gnr-bnr" control={<Radio />} label="Gårds- og bruksnummer" />
            <FormControlLabel checked={locationType === 2} onClick={() => onChangeLocationType(2)} className='radio-form-location' value="gateadresse" control={<Radio />} label="Gateadresse" />
            <FormControlLabel checked={locationType === 3} onClick={() => onChangeLocationType(3)} className='radio-form-location' value="gps-koordinater" control={<Radio />} label="GPS-koordinater" />
          </RadioGroup>
        </FormControl>
        {locationType === 1 && <AreaLocationLookup name="area-location" onChange={setLocation}></AreaLocationLookup>}
        {locationType === 2 && <AddressLookup name="address-location" onChange={setLocation}></AddressLookup>}
        {locationType === 3 && <GPSLookup name="gps-location" onChange={setLocation}></GPSLookup>}
        {selectedLocationOption ?
          <Stack paddingTop={5}>
            <Typography variant="body1" color={"gray"}>
              Valgt plassering:
            </Typography>
            <Step4Summary />
          </Stack> : <></>
        }
      </Stack>
    </>
  );
};
