import { AppBar, Button,Stack, Typography } from '@mui/material';
import { useState } from 'react';

type SuccessScreenProps = {
  onStart: () => void;
};

const SuccessScreen = ({ onStart }: SuccessScreenProps) => {
  let [checked, setChecked] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <div style={{ textAlign: 'left', padding: 20, }}>
        <Stack direction="column" spacing={2} alignItems="start" gap={"20px"} padding="10vh">

          <Stack direction="row" gap="5px">
            <Typography variant="h2" style={{ fontWeight: "bold" }} >
              Saken er mottatt
            </Typography>
          </Stack>
          <Typography variant="h6" alignItems={"center"}>

            Ditt saksnummer er <b>608RTS08H</b>. <br /> <br />

            Du har mottatt en kopi på din mailadresse.<br /><br />

            Din søknad er videresendt til nettselskapet Lede, som er nettselskapet i området du har søkt om tilknytning.<br /><br />

            Du vil få svar på forespørselen i løpet av 4 uker, og vil da motta en mail om dette.<br /><br />


          </Typography>

          <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0, background: "white" }}>
            <div style={{ display: 'flex', justifyContent: 'center', gap: "35px", padding: 30 }}>
              {/* <Button size='large' onClick={() => window.location.hash} variant="outlined">
                Tilbake
              </Button> */}
              <Button size='large' type="submit" onClick={onStart} variant="contained" color="primary">
                Avslutt
              </Button>
            </div>
          </AppBar>
        </Stack>
      </div>
    </>
  );
};

export default SuccessScreen;