export const postFiles = (files: FileList, formId: string): Promise<any> => {
  let body = new FormData();
  console.log("PostFiles", formId, files)
  body.append('formId', formId);
  Array.from(files).forEach(file => {
    body.append('files', file, file.name);
  })
  const headers = {
    // NB: Content-type *must* be unset for fetch to add boundary
    // "Content-Type": "multipart/form-data",
    "api-version": "1.0"
  }
  const request = new Request("/api/formfiles", {
    method: "POST",
    headers,
    body
  });
  return fetch(request);
}

/*
type UploadProgress = {
  value: number;
  max: number;
}

  const uploadProgress = new ReadableStream({
    start(controller) {
      console.log("starting upload, request.bodyUsed:", request.bodyUsed);
      controller.enqueue(request.bodyUsed);
    },
    pull(controller) {
      if (request.bodyUsed) {
        controller.close();
      }
      controller.enqueue(request.bodyUsed);
      console.log("pull, request.bodyUsed:", request.bodyUsed);
    },
    cancel(reason) {
      console.log(reason);
    }
  });

  const [fileUpload, reader] = [
    upload(request).catch(e => {
      reader.cancel();
      console.log(e);
      throw e
    }),
    uploadProgress.getReader()
  ];

  const processUploadRequest = ({value, done}) => {
    console.log("value:", value);
    if (value || done) {
      console.log("upload complete, request.bodyUsed:", request.bodyUsed);
      progress.value = progress.max;
      return reader.closed.then(() => fileUpload);
    };
    console.log("upload progress:", value);
    if (progress.value < file.size) {
      progress.value += 1;
    }
    return reader.read().then(result => processUploadRequest(result));
  };

  reader.read()
    .then(({value, done}) => processUploadRequest({value, done}))
    .then(response => response.text()).then(text => {
      console.log("response:", text);
      progress.value = progress.max;
    })
    .catch(err => console.log("upload error:", err));
};

const getFiles = () : Promise<any> => {
  return fetch(API_URL);
};


*/
