export * from './areaProvider'
export * from './postFiles'
export * from './postFormData'

export function SearchAddress() {
    return [];
}

export enum SubmitStatus {
  INIT,
  READY,
  SUBMITTING,
  SUBMITTED,
  ERROR,
  UPLOADING,
  UPLOAD_ERROR
}
