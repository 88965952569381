import React from 'react';
import { AppBar, Button, IconButton, Toolbar, Typography, } from '@mui/material';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => window.location.hash = '/'}
        >
          <BoltOutlinedIcon />
        </IconButton>
        <Typography
          style={{ cursor: 'pointer' }}
          variant="h6"
          component="div" sx={{ flexGrow: 1 }}
          onClick={() => window.location.hash = '/'}>
          {title}
        </Typography>
        <Button color="inherit">Logg inn</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;