import { Button, Stack, Typography } from '@mui/material';
import { FrontPageBackground } from '../../assets/svg';

type StartScreenProps = {
  onStart: () => void;
};

const StartScreen = ({ onStart }: StartScreenProps) => {
  return (
    <>
      <div style={{ textAlign: 'left', padding: 20, width: '60%' }}>
        <Stack direction="column" spacing={2} alignItems="start" gap={"20px"} padding="10vh">
          <Typography variant="h2" style={{ fontWeight: "bold" }} >
            Tilknytning for næring og <br /> offentlig virksomhet
          </Typography>
          <Typography variant="h6" >
            Ønsker du å knytte deg til strømnettet? Har du ett konkret prosjekt?<br />
            Effektbehov og lokasjon må være avklart for å bestille kapasitet.<br />
            Denne forespørselen starter prosessen for nettilknytning.<br /><br />

            Hvis du ønsker tidligfase veiledning kan du trykke <a href=''>her</a>.
          </Typography>
          <Stack justifyContent={'center'} alignItems="center" direction="row" gap="10px">
            <Button size='large' variant="contained" color="primary" onClick={onStart}>
              Start
            </Button>
            <Button disabled size='large' variant="outlined" color="secondary" onClick={() => { }}>
              Logg inn
            </Button>
          </Stack>
          <Typography variant="body1" >
            Se hvor det finnes ledig kapasitet i strømnettet <a target={'_blank'} href='https://wattapp.no'>her</a>.
          </Typography>
        </Stack>
      </div>
      <FrontPageBackground className={"startscreen-backgroung-image"}></FrontPageBackground>
    </>
  );
};

export default StartScreen;