import { createTheme, Theme, ThemeProvider } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

export const muiTheme: Theme = createTheme({
  palette: {
    primary: {
      main: '#004e8c', 
    },
    secondary: {
      main: '#3f51b5', 
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // fontSize: '1rem',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: 36,
          height: 36,
          '&$completed': {
            color: '#a5d6a7',
          },
          '&$active': {
            color: blueGrey[500],
          },
        },
        text: {
          fontSize: '1rem',
          padding: 2
        },
        completed: {},
        active: {},
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '1rem',
          color: blueGrey[500],
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          top: 18
        },
      },
    },
  },
});
 