import { FC, useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Stack, } from '@mui/material';
import FormHeader from '../../../FormHeader';
import { FormContext } from '../../FormContext';


type Step5Props = {
};

const months: string[] = [
  "Q1",
  "Q2",
  "Q3",
  "Q4",
];

export const Step5: FC<Step5Props> = () => {

  const { formData, handleFormChange } = useContext(FormContext);
  const year = new Date().getFullYear();
  const years = Array.from({ length: 15 }, (v, idx) => year + idx);

  const yearChoices = years.map((year: number) => <MenuItem value={year} key={year}>{year}</MenuItem>)
  const monthChoices = months.map((quarter: string) => <MenuItem value={quarter} key={quarter}>{quarter}</MenuItem>)
  return (
    <>
      <FormHeader header='Når ønsker dere å bli tilknyttet?' subHeader=''></FormHeader>
      <Stack justifyContent={'center'} alignItems="center" direction="row">
        <FormControl sx={{ m: 1, minWidth: 250 }}>
          <InputLabel required id="label-year">År</InputLabel>
          <Select
            label="År Æ"
            labelId="label-year"
            id="select-year"
            name='year'
            onChange={(e: any) => handleFormChange(e)}
            value={formData?.year || ''}
            required
          >
            <MenuItem selected disabled value=''><i>Velg år</i></MenuItem>
            {yearChoices}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 250 }}>
          <InputLabel required id="label-quarter">Kvartal</InputLabel>
          <Select
            label="Kvartal"
            name="quarter"
            value={formData?.quarter || ''}
            onChange={(e: any) => handleFormChange(e)}
            labelId="label-quarter"
            id="select-quarter"
            required
            >
            <MenuItem selected disabled value=''><i>Velg kvartal</i></MenuItem>
            {monthChoices}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
};
