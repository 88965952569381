import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { areaProvider } from '../../../../../services';
import { FormContext } from '../../../FormContext';

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

interface IAddressLookup {
  name: string;
  onChange: Function;
};

export const AddressLookup: React.FC<IAddressLookup> = ({ onChange }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string | null>(null); // Null must be the init value to get a default without messing up when clearing
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedLocationOption, setProvider } = useContext(FormContext);

  const debouncedSearchTerm = useDebounce(inputValue || '', 300);

  useEffect(() => {
    const fetchData = async () => {
      if (!inputValue) return
      setIsLoading(true);
      try {
        const response = await fetch(`https://ws.geonorge.no/adresser/v1/sok?sok=${encodeURIComponent(inputValue)}&fuzzy=false&utkoordsys=4258&treffPerSide=10&side=0&asciiKompatibel=true`);
        let json = await response.json();
        setData(json.adresser);
      } catch (error) {
        // setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (debouncedSearchTerm) {
      fetchData();
    }
  }, [debouncedSearchTerm]);
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.adressetekst === option.adressetekst}
      getOptionLabel={(option: any) => option.adressetekst}
      noOptionsText="Ingen treff"
      options={data}
      loading={isLoading}
      inputValue={(inputValue ?? selectedLocationOption?.adressetekst) || ''}
      onChange={(event, value) => {
        setProvider(areaProvider(value.representasjonspunkt));
        onChange(value);
      }}
      loadingText="Laster..."
      onInputChange={(event: any, newInputValue: string) => { if (event) setInputValue(newInputValue) }}
      renderOption={(props, option: any) => (
        <li {...props} key={`${option.kommunenummer}_${option.adressetekst}`}>
          <Typography variant="body1"> {option.adressetekst},</Typography> &nbsp;
          <Typography variant="body1" style={{ color: 'gray' }}>
            {option.postnummer} {option.kommunenavn}
          </Typography>
        </li>
      )}
      renderInput={(params) => (<TextField
        {...params}
        label="Søk i adresseregister"
        onKeyDown={handleKeyDown}
        InputProps={{
          ...params.InputProps,
          endAdornment: (<>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>),
        }}
      />)}
    />
  );
}