
import * as React from 'react';
import { FC, useContext } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, FormLabel, Card, CardActionArea, CardContent, FormControl, FormControlLabel, Radio, RadioGroup, Slide, Stack, TextField, Typography } from '@mui/material';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import FormHeader from '../../../FormHeader';
import { ExpandMore } from '@mui/icons-material';
import { FormContext } from '../../FormContext';

type Step2Props = {};

export const Step2: FC<Step2Props> = () => {
  const { formData, handleFormChange, selectedIndustryType, setSelectedIndustryType } = useContext(FormContext);
  return (<>
    <FormHeader header='Skal virksomheten produsere eller bruke strøm?' subHeader=''></FormHeader>
    <Stack className='power-box-selection' justifyContent={'center'} alignItems="center" direction="row" gap="15px">
      <Card sx={{ width: 300 }}>
        <CardActionArea style={{ backgroundColor: selectedIndustryType === 1 ? "#f5f5f5" : "inherit" }} sx={{ padding: 2 }} onClick={() => setSelectedIndustryType(1)}>
          <CardContent>
            <Stack justifyContent={'center'} alignItems="center" gap={1}>
              <BoltOutlinedIcon className='selection-icon' />
              <Typography gutterBottom variant="h6" component="div">
                Produksjon
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Vi skal produsere strøm
              </Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card sx={{ width: 300 }}>
        <CardActionArea sx={{ padding: 2 }} style={{ backgroundColor: selectedIndustryType === 2 ? "#f5f5f5" : "inherit" }} onClick={() => setSelectedIndustryType(2)}>
          <CardContent>
            <Stack justifyContent={'center'} alignItems="center" gap={1}>
              <TrendingUpOutlinedIcon className='selection-icon' />
              <Typography gutterBottom variant="h6" component="div">
                Forbruk
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Vi skal bruke strøm
              </Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card sx={{ width: 300 }}>
        <CardActionArea sx={{ padding: 2 }} style={{ backgroundColor: selectedIndustryType === 3 ? "#f5f5f5" : "inherit" }} onClick={() => setSelectedIndustryType(3)}>
          <CardContent>
            <Stack justifyContent={'center'} alignItems="center" gap={1}>
              <BoltOutlinedIcon className='selection-icon' />
              <Typography gutterBottom variant="h6" component="div">
                En miks av begge
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Vi skal bruke og produsere strøm
              </Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Stack>
    <Stack justifyContent={'center'} alignContent={"center"} paddingTop="60px" gap={5} maxWidth={935}>
      <Slide direction='right' in={selectedIndustryType === 1 || selectedIndustryType === 3} unmountOnExit >
        <Stack>
          <FormControl>
            <FormLabel
              style={{ display: selectedIndustryType !== 3 ? 'none' : 'block' }}
              id="radio-button-group-production-label">
              Produksjon
            </FormLabel>
            <RadioGroup row
              aria-labelledby="radio-button-group-production-label"
              name="industryProduction"
              style={{ justifyContent: 'center' }}
              value={formData && formData.industryProduction}
              onChange={handleFormChange}>
              <FormControlLabel className='radio-form-industry' value="Solkraft" control={<Radio required />} label="Solkraft" />
              <FormControlLabel className='radio-form-industry' value="Vindkraft" control={<Radio required />} label="Vindkraft" />
              <FormControlLabel className='radio-form-industry' value="Biobrensel" control={<Radio required />} label="Biobrensel" />
              <FormControlLabel className='radio-form-industry' value="Naturgass" control={<Radio required />} label="Naturgass" />
              <FormControlLabel className='radio-form-industry' value="Kjernekraft" control={<Radio required />} label="Kjernekraft" />
              <FormControlLabel className='radio-form-industry' value="Vannkraft" control={<Radio required />} label="Vannkraft" />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Slide>
      <Slide direction='right' in={selectedIndustryType === 2 || selectedIndustryType === 3} unmountOnExit >
        <Stack>
          <FormControl>
            <FormLabel
              style={{ display: selectedIndustryType !== 3 ? 'none' : 'block' }}
              id="radio-button-group-consumption-label">
              Forbruk
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-button-group-consumption-label"
              name="industryConsumption"
              value={formData && formData.industryConsumption}
              onChange={handleFormChange}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="accordion-Industri-content"
                  id="Industri-accordion-summary">
                  <Typography>Industri</Typography>
                </AccordionSummary>
                <AccordionDetails >
                  <FormControlLabel className='radio-form-industry' value="Ammoniakk" control={<Radio required />} label="Ammoniakk" />
                  <FormControlLabel className='radio-form-industry' value="Batteri (eller Batteriverdikjeden)" control={<Radio required />} label="Batteri (eller Batteriverdikjeden)" />
                  <FormControlLabel className='radio-form-industry' value="Datasenter" control={<Radio required />} label="Datasenter" />
                  <FormControlLabel className='radio-form-industry' value="Hydrogen" control={<Radio required />} label="Hydrogen" />
                  <FormControlLabel className='radio-form-industry' value="E-fuel" control={<Radio required />} label="E-fuel" />
                  <FormControlLabel className='radio-form-industry' value="CO2-fangst" control={<Radio required />} label="CO2-fangst" />
                  <FormControlLabel className='radio-form-industry' value="Bergverksdrift og utvinning" control={<Radio required />} label="Bergverksdrift og utvinning" />
                  <FormControlLabel className='radio-form-industry' value="Kjemisk- og prosessindustri" control={<Radio required />} label="Kjemisk- og prosessindustri" />
                  <FormControlLabel className='radio-form-industry' value="Olje og gass" control={<Radio required />} label="Olje og gass" />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="accordion-offentligvirksomhet-content"
                  id="industri-accordion-summary">
                  <Typography>Offentlig virksomhet</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel className='radio-form-industry' value="Helse" control={<Radio required />} label="Helse" />
                  <FormControlLabel className='radio-form-industry' value="Forsvar" control={<Radio required />} label="Forsvar" />
                  <FormControlLabel className='radio-form-industry' value="Offentlig anlegg" control={<Radio required />} label="Offentlig anlegg" />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="bolig-accordion-summary" >
                  <Typography>Bolig</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel className='radio-form-industry' value="Boligfelt / Leiligheter" control={<Radio required />} label="Boligfelt / Leiligheter" />
                  <FormControlLabel className='radio-form-industry' value="Hyttefelt" control={<Radio required />} label="Hyttefelt" />
                  <FormControlLabel className='radio-form-industry' value="Boligbygg med mindre næring" control={<Radio required />} label="Boligbygg med mindre næring" />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="naering-accordion-summary">
                  <Typography>Næring</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel className='radio-form-industry' value="Landbruk / Matproduksjon" control={<Radio required />} label="Landbruk / Matproduksjon" />
                  <FormControlLabel className='radio-form-industry' value="Havbruk / Akvakultur / Landbasert oppdrett" control={<Radio required />} label="Havbruk / Akvakultur / Landbasert oppdrett" />
                  <FormControlLabel className='radio-form-industry' value="Matproduksjon" control={<Radio required />} label="Matproduksjon" />
                  <FormControlLabel className='radio-form-industry' value="Næringspark/-område (kontor, mindre industri)" control={<Radio required />} label="Næringspark/-område (kontor, mindre industri)" />
                  <FormControlLabel className='radio-form-industry' value="Varehandel" control={<Radio required />} label="Varehandel" />
                  <FormControlLabel className='radio-form-industry' value="Lager og logistikk" control={<Radio required />} label="Lager og logistikk" />
                  <FormControlLabel className='radio-form-industry' value="Annen næring" control={<Radio required />} label="Annen næring" />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="transport-accordion-summary" >
                  <Typography>Lading</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel className='radio-form-industry' value="Ferge" control={<Radio required />} label="Ferge" />
                  <FormControlLabel className='radio-form-industry' value="Hurtigladestasjon (elbil)" control={<Radio required />} label="Hurtigladestasjon (elbil)" />
                  <FormControlLabel className='radio-form-industry' value="Kollektiv" control={<Radio required />} label="Kollektiv" />
                  <FormControlLabel className='radio-form-industry' value="Landstrøm" control={<Radio required />} label="Landstrøm" />
                  <FormControlLabel className='radio-form-industry' value="Lufthavn" control={<Radio required />} label="Lufthavn" />
                  <FormControlLabel className='radio-form-industry' value="Tungtransport" control={<Radio required />} label="Tungtransport" />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header">
                  <Typography>Infrastruktur</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel className='radio-form-industry' value="Fjernvarme /-kjøling" control={<Radio required />} label="Fjernvarme /-kjøling" />
                  <FormControlLabel className='radio-form-industry' value="Vei/tunell" control={<Radio required />} label="Vei/tunell" />
                  <FormControlLabel className='radio-form-industry' value="Bane" control={<Radio required />} label="Bane" />
                  <FormControlLabel className='radio-form-industry' value="Vann og avløp" control={<Radio required />} label="Vann og avløp" />
                  <FormControlLabel className='radio-form-industry' value="Elkjel" control={<Radio required />} label="Elkjel" />
                  <FormControlLabel className='radio-form-industry' value="Byggstrøm" control={<Radio required />} label="Byggestrøm" />
                  <FormControlLabel className='radio-form-industry' value="Jernbane" control={<Radio required />} label="Jernbane" />                    
                </AccordionDetails>
              </Accordion>
            </RadioGroup>
          </FormControl>
        </Stack>
      </Slide>
    </Stack>
  </>);
};
