export enum ApplicationType {
    Connection = 0, // "Application for grid connection",
    Estimate = 1 // "Request for price estimate for grid connection"
}

export interface Document {
  name: string;
}

export interface Industry {
  consumption?: string;
  production?: string;
}

export interface Organisation {
  id: number;
  name: string;
  contact: Person;
  industry: Industry;
}

export interface Provider {
  name: string;
  email: string;
  code: string;
  type: string;
  url?: string,
}

// ok
export interface Person {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

interface GeoNorgeV1 {
  adressenavn: string;
  adressetekst: string;
  adressetilleggsnavn: string | null,
  adressekode: number,
  nummer: number,
  bokstav: string,
  kommunenummer: string,
  kommunenavn: string,
  gardsnummer: number,
  bruksnummer: number,
  festenummer: number,
  undernummer: number | null,
  bruksenhetsnummer: string[],
  objtype: "Vegadresse",
  poststed: string,
  postnummer: string,
  adressetekstutenadressetilleggsnavn: string,
  stedfestingverifisert: boolean,
  representasjonspunkt: Coordinates,
  oppdateringsdato: string,
}

export interface Place {
  name: string;
  address: string;
  coordinates: Coordinates;
  location: GeoNorgeV1;
}

interface TimeSchedule {
  year: number;
  quarter: string;
}

export interface Connection {
  loadSize: number;
  voltageLevel: VoltageLevel;
  estimatedAnnualProduction?: number;
  estimatedAnnualConsumption?: number;
  timeSchedule: TimeSchedule; // Define the type for Time Schedule
  provider: Provider;
  voltage?: number;
  name?: string;
  id?: any;
  coordinates?: Coordinates;
}

export interface Application {
  title: string;
  description: string;
  type: ApplicationType;
  applicant: Organisation;
  place: Place;
  connection: Connection;
  attachments: string[];
}

export enum VoltageLevel {
  LOW = "LOW",
  HIGH = "HIGH",
  REGIONAL = "REGIONAL"
}

export const LowVoltages = [
  0.23, 0.40, 0.69 // Voltages in kV
]

export interface Coordinates {
  epsg: string | null,
  lat: number,
  lon: number
}

export enum IndustryType {
  PRODUCTION = 1,
  CONSUMPTION = 2,
  MIXED = 3,
  NONE = 0
}

export enum LocationType {
  ADDRESS = 1,
  AREANUMBER = 2,
  GPS = 3,
  NONE = 0
}
