
import { SubmitStatus, postFiles } from '.'
import { ApplicationType, Application, Organisation, Industry, Person, Place, Connection, VoltageLevel } from '../types';

interface ConnectionResponse {
  dataJsonPath: string,
  formId: string
}

export async function postFormData(formData: any, setFormId: React.Dispatch<React.SetStateAction<string>>, setSubmitStatus: (status: SubmitStatus) => any) {
  if (!formData) return
  setSubmitStatus(SubmitStatus.SUBMITTING);

  const attachments: string[] = formData.uploadFiles?.length > 0
    ? Array.from(formData.uploadFiles as File[]).map((file: File) => file.name)
    : []
  const title = `Søknad om tilknytning for ${formData.orgName} til ${formData.provider.name}`;

  const industry: Industry = {
    production: formData.industryProduction || undefined,
    consumption: formData.industryConsumption || undefined
  }

  const contact: Person = {
    id: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    phone: formData.phone
  };
  
  const place: Place = {
    name: formData.location.adressetekst,
    address: formData.location.adressetekst,
    coordinates: formData.location.representasjonspunkt,
    location: formData.location
  };

  const applicant: Organisation = {
    id: formData.orgNr,
    name: formData.orgName,
    contact,
    industry
  };

  const connection: Connection = {
    loadSize: formData.effectSize,
    estimatedAnnualProduction: formData.estimatedProduction,
    estimatedAnnualConsumption: formData.estimatedConsumption,
    voltageLevel: formData.voltageLevel,
    voltage: formData.voltageLevel === VoltageLevel.LOW ? formData.voltage : undefined,
    timeSchedule: {
      quarter: formData.quarter,
      year: formData.year
    },
    provider: formData.provider
  };
 
  const applicationData: Application = {
    title,
    description: formData.description,
    type: ApplicationType.Connection,
    applicant,
    place,
    connection,
    attachments
  };

  let response, status = SubmitStatus.ERROR;
  try {
    response = await postJson(applicationData);
  } catch (error) {
    console.log('Upload error', error);
  }
  if (response) {
    setFormId(response.formId);
    if (formData.uploadFiles?.length) {
      setSubmitStatus(SubmitStatus.UPLOADING);
      const upload = await postFiles(formData.uploadFiles, response.formId);
      status = upload?.ok ? SubmitStatus.SUBMITTED : SubmitStatus.UPLOAD_ERROR;
    } else {
      status = SubmitStatus.SUBMITTED;
    }
  }
  setSubmitStatus(status);
}

async function postJson(applicationData: Application): Promise<ConnectionResponse | null> {
  try {
    const body = JSON.stringify(applicationData);
    const headers: HeadersInit = {
      "Content-Type": "application/json",
      "api-version": "1.0"
    };
    const data = await fetch("/api/formdata", { method: "POST", headers, body });
    const response: ConnectionResponse = await data.json();
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

