import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { FormContext } from '../../../FormContext';

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

interface BRREGLookupProps {
};

const formatOrgNr = (orgNr: string) => {
  return orgNr ? orgNr.slice(0, 3) + ' ' + orgNr.slice(3, 6) + ' ' + orgNr.slice(6, 9) : ''
} 

export const BRREGLookup: React.FC<BRREGLookupProps> = () => {
  const { formData, setOrganisation } = useContext(FormContext);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(formData?.orgName || '');
  const [dataResponse, setDataReponse] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>({ navn: formData?.orgName, organsisasjonsnummer: formData?.orgNr });
  const [loading, setLoading] = useState(false);

  const debouncedSearchTerm = useDebounce(inputValue, 300);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter?navn=${inputValue}&size=5`);
        let json = await response.json();
        setDataReponse(json._embedded.enheter.map((item: any) => ({
          navn: item?.navn,
          organisasjonsnummer: item?.organisasjonsnummer
        })));
      } catch (error) {
        // setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (debouncedSearchTerm) {
      fetchData();
    }
  }, [debouncedSearchTerm]);
  return (<>
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      noOptionsText="Ingen treff"
      isOptionEqualToValue={(option, value) => option.navn === option.navn}
      getOptionLabel={(option: any) => option?.navn || ''}
      options={dataResponse}
      loading={loading}
      value={selectedOption}
      inputValue={inputValue}
      autoHighlight
      onChange={(event, value) => {
        if (!event) return
        setSelectedOption(value);
        setOrganisation(value?.navn, value?.organisasjonsnummer);
      }
      }
      onInputChange={(event: any, newInputValue: string) => {
        event && setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Selskap"
          name='organisation'
          required
          value={formData?.orgName}
          InputProps={{
            ...params.InputProps,
            endAdornment: (<>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>),
          }}
        />
      )}
      renderOption={(props, option: any) => (
        <li {...props} key={`${option.organisasjonsnummer}`}>
          <Typography variant="body1"> {option.navn},</Typography> &nbsp;
          <Typography variant="body1" style={{ color: 'gray' }}>
            {formatOrgNr(option.organisasjonsnummer)}
          </Typography>
        </li>
      )}
    />
    <TextField
      fullWidth
      name='orgNr'
      margin="normal"
      label="Organisasjonsnummer"
      value={formatOrgNr(formData ? formData.orgNr : selectedOption?.organisasjonsnummer)}
      InputLabelProps={{ shrink: formData?.orgNr !== '' }}
      InputProps={{ readOnly: true }}
    />
  </>);
}