import { Button, Stack, TextField } from '@mui/material';
import * as React from 'react';
import { KeyboardEvent } from 'react';
import { useState, useContext } from 'react';
import { areaProvider } from '../../../../../services';
import { FormContext } from '../../../FormContext';

interface IGPSLookup {
    name: string;
    onChange: Function;
};

export const GPSLookup: React.FC<IGPSLookup> = ({ onChange }) => {
    const { selectedLocationOption, } = useContext(FormContext);
    const [longitude, setLongitude] = useState<number>();
    const [latitude, setLatitude] = useState<number>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`https://ws.geonorge.no/adresser/v1/punktsok?lat=${latitude}&lon=${longitude}&radius=1000&koordsys=4258&utkoordsys=4258&treffPerSide=10&side=0&asciiKompatibel=true`);
            let json = await response.json();
            const value = json.adresser[0];
            value.nettselskap = areaProvider(value.representasjonspunkt);
            onChange(json.adresser.length !== 0 ? value : null);
        } catch (error) {
            // setError(error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    
    return (
        <>
            <Stack justifyContent={'center'} alignItems="center" direction="row" gap="5px">
                <TextField
                    fullWidth
                    value={(longitude ?? selectedLocationOption?.representasjonspunkt.lon.toPrecision(8)) || ''}
                    onChange={(e) => setLongitude(+e.target.value)}
                    margin="normal"
                    variant="outlined"
                    type={"number"}
                    onKeyDown={handleKeyDown}
                    label="Lengdegrad">
                </TextField>
                <TextField
                    value={(latitude ?? selectedLocationOption?.representasjonspunkt.lat.toPrecision(8)) || ''}
                    onChange={(e) => setLatitude(+e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    type={"number"}
                    onKeyDown={handleKeyDown}
                    label="Breddegrad">
                </TextField>
            </Stack>
            <Button size='large' variant="contained" color="primary" onClick={fetchData}>
                Velg plassering
            </Button>
        </>
    );
}