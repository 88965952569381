import { FC, useContext } from 'react';
import { Button } from '@mui/material';
import { FormContext } from '../FormContext';
import { SubmitStatus, postFormData } from '../../../services';

type ButtonProps = {
  activeStep: number,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  formId: string,
  setFormId: React.Dispatch<React.SetStateAction<string>>
};

export const ButtonBar: FC<ButtonProps> = ({ activeStep, setActiveStep, formId, setFormId }) => {
  const { formData, submitStatus, setSubmitStatus } = useContext(FormContext);

  const handlePrevStep = () => {
    setActiveStep(activeStep === 0 ? 0 : activeStep - 1);
  };

  const finalise = () => {
    if (!formId) postFormData(formData, setFormId, setSubmitStatus)
    setActiveStep(7);
  }

  const submittable = activeStep === 6 && submitStatus === SubmitStatus.READY

  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: "20px", padding: 30 }}>
      <Button size='large' disabled={activeStep === 0} onClick={handlePrevStep} variant="outlined" color="primary">
        Tilbake
      </Button>
      {activeStep < 6
      ? <Button size='large' type='submit' variant='contained' color='primary' autoCorrect='off'>
        Neste
      </Button>
      : <Button size='large' disabled={!submittable} onClick={finalise} variant="contained" color="primary" autoCorrect='off'>
        Send inn søknad
      </Button>}
    </div>
  )
}
