import { FC, useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import FormHeader from '../../../FormHeader';
import { FormContext } from '../../FormContext';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { SubmitStatus } from '../../../../services';

type SummaryProps = {
  formId: string
};

export const Submit: FC<SummaryProps> = ({ formId }) => {
  const { formData, submitStatus } = useContext(FormContext);
  if (!formData) return (<></>)
  console.log("Submit", formId, submitStatus)
  return (<>
    <FormHeader header={formId ? 'Søknad sendt' : 'Sender søknad'} subHeader='' />
    <Stack gap={4}>
      {formId ? <>
        <Stack direction="row" gap="5px">
          <CheckCircleOutlineRoundedIcon color='success' />
          <Typography variant="h3" style={{ fontWeight: "bold" }} >
            Saken er mottatt
          </Typography>
        </Stack>
        <Typography variant="h6" alignItems="center">
          Ditt saksnummer er <b>{formId}</b>. <br /> <br />
          Du har mottatt en kopi på din mailadresse.<br /><br />
          Din søknad er videresendt til nettselskapet {formData.provider.name}, som er nettselskapet i området du har søkt om tilknytning.<br /><br />
          Du vil få svar på forespørselen i løpet av 4 uker, og vil da motta en mail om dette.<br /><br />
        </Typography>
      </> : <Stack direction="row" gap="5px">
        <Typography variant="h5">
          Sender inn...
        </Typography>
      </Stack>}
      {formData.uploadFiles?.length && <>
        <Typography variant="body1">
          Filer
        </Typography>
        {submitStatus === SubmitStatus.UPLOADING && <>
          <UploadFileIcon color="secondary" />
        </>}
        {Array.from(formData.uploadFiles).map((file: File) => (
          <Typography variant="body2">
            {file.name}
          </Typography>
        ))}
      </>}
      {submitStatus === SubmitStatus.ERROR && <Stack direction="row" gap="5px">
        <ErrorOutlineIcon sx={{ color: 'red' }} />
        <Typography variant='h5' style={{ alignSelf: 'center' }}>
          Feil i innsending!
        </Typography>
      </Stack>}
      {submitStatus === SubmitStatus.UPLOAD_ERROR && <Stack direction="row" gap="5px">
        <ErrorOutlineIcon sx={{ color: 'red' }} />
        <Typography variant='h5' style={{ alignSelf: 'center' }}>
          Feil i filopplasting!
        </Typography>
      </Stack>}
    </Stack>
  </>);
};
 