import { AppBar, Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { useState } from 'react';

type ConsentScreenProps = {
  onStart: () => void;
};

const ConsentScreen = ({ onStart }: ConsentScreenProps) => {
  let [checked, setChecked] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <div style={{ textAlign: 'left', padding: 20, }}>
        <Stack direction="column" spacing={2} alignItems="start" gap={"20px"} padding="10vh">
          <Typography variant="h2" style={{ fontWeight: "bold" }} >
            Før du begynner
          </Typography>
          <Typography variant="h6" >
            Når du foresøpør kapasitet, vil vi gjennomføre en vurdering av hva som kreves for å knytte nytt forbruk eller ny produksjon på den lokasjonen du spør om. Dersom det kreves tiltak for tilknytningen, vil nettselskapet svare deg med et grovt og uforpliktende kostnadsestimat og en tidsplan for realisering.
            <br /><br />
            En kapasitetsforespørsel er ikke en reservasjon og gir ikke kø-plass for fremtidig nettkapasitet.
          </Typography>
          
          <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} checked={checked} onChange={handleChange} />} label="Jeg bekrefter at vi har en lokasjon og ett strømbehov avklart." />
          <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0, background: "white" }}>
            <div style={{ display: 'flex', justifyContent: 'center', gap: "35px", padding: 30 }}>
              {/* <Button size='large' onClick={() => window.location.hash} variant="outlined">
                Tilbake
              </Button> */}
              <Button size='large' disabled={!checked} type="submit" onClick={onStart} variant="contained" color="primary">
                Start
              </Button>
            </div>
          </AppBar>
        </Stack>
      </div>
    </>
  );
};

export default ConsentScreen;