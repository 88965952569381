import { FC, useContext } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import FormHeader from '../../../FormHeader';
import { FormContext } from '../../FormContext';
import { Step4Summary } from '../../steps/Step4';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { VoltageLevel } from '../../../../types';

type SummaryProps = {
};

export const Summary: FC<SummaryProps> = () => {
  const { formData } = useContext(FormContext);
  if (!formData) return (<></>)
  const disabledInput = { readOnly: true }
  console.log("Check", formData)
  return (<>
    <FormHeader header='Oppsummering' subHeader='' />
    <Stack gap={4}>
      <Stack>
        <Stack direction="row" gap="5px">
          <CheckCircleOutlineRoundedIcon fontSize='medium' color={'success'} />
          <Typography variant="h5">
            Kontaktinformasjon
          </Typography>
        </Stack>
        <Stack justifyContent={'center'} alignItems="center" direction="row" gap="5px">
          <TextField
            focused
            label="Fornavn"
            name="name"
            value={formData.firstName}
            fullWidth
            margin="normal"
            type="text"
            InputProps={disabledInput}
            variant="standard" />
          <TextField
            focused
            label="Etternavn"
            name="name"
            value={formData.lastName}
            fullWidth
            margin="normal"
            type="text"
            InputProps={disabledInput}
            variant="standard" />
          </Stack>
        <Stack justifyContent={'center'} alignItems="center" direction="row" gap="5px">
        <TextField
          label="E-post"
          focused
          name="email"
          value={formData.email}
          fullWidth
          margin="normal"
          type="email"
          InputProps={disabledInput}
          variant="standard" />
        <TextField
          focused
          label="Telefon"
          name="phone"
          value={formData.phone}
          fullWidth
          margin="normal"
          type="tel"
          InputProps={disabledInput}
          variant="standard" />
        </Stack>
      </Stack>
      <Stack>
        <Stack direction="row" gap="5px">
          <CheckCircleOutlineRoundedIcon fontSize='medium' color={'success'} />
          <Typography variant="h5">
            Virksomhet
          </Typography>
        </Stack>
        {formData.industryProduction && <TextField
          label="Produksjon"
          focused
          name="industryProduction"
          value={formData.industryProduction}
          fullWidth
          margin="normal"
          InputProps={disabledInput}
          variant="standard"
        />}
        {formData.industryConsumption && <TextField
          focused
          label="Forbruk"
          name="industryConsumption"
          value={formData.industryConsumption}
          fullWidth
          margin="normal"
          InputProps={disabledInput}
          variant="standard"
        />}
      </Stack>
      <Stack>
        <Stack direction="row" gap="5px">
          <CheckCircleOutlineRoundedIcon fontSize='medium' color={'success'}></CheckCircleOutlineRoundedIcon>
          <Typography variant="h5">
              Behov
          </Typography>
        </Stack>
        <Stack direction="row" gap="5px">
          <TextField
            label="Max effektstørrelse"
            focused
            name="email"
            value={formData && formData.effectSize}
            fullWidth
            margin="normal"
            type="email"
            InputProps={disabledInput}
            variant="standard" />
          <TextField
            focused
            label="Spenningsnivå"
            name="name"
            value={
              formData?.voltageLevel === VoltageLevel.LOW ? formData?.voltage :
              formData?.voltageLevel === VoltageLevel.HIGH ? "Høyspent" :
              "Regionalnett"
            }
            fullWidth
            margin="normal"
            type="text"
            InputProps={disabledInput}
            variant="standard" />
          {formData?.estimatedProduction && <TextField
            focused
            label="Estimert årlig produksjon"
            name="estimatedProduction"
            value={formData.estimatedProduction}
            fullWidth
            margin="normal"
            InputProps={disabledInput}
            variant="standard" />}
          {formData?.estimatedConsumption && <TextField
            focused
            label="Estimert årlig forbruk"
            name="estimatedConsumption"
            value={formData.estimatedConsumption}
            fullWidth
            margin="normal"
            InputProps={disabledInput}
            variant="standard" />}
        </Stack>
      </Stack>
      <Stack >
        <Stack direction="row" gap="5px">
          <CheckCircleOutlineRoundedIcon fontSize='medium' color={'success'}></CheckCircleOutlineRoundedIcon>
          <Typography variant="h5">
            Lokasjon
          </Typography>
        </Stack>
        <Step4Summary />
      </Stack>
      <Stack>
        <Stack direction="row" gap="5px">
          <CheckCircleOutlineRoundedIcon fontSize='medium' color={'success'}></CheckCircleOutlineRoundedIcon>
          <Typography variant="h5">
            Tidspunkt
          </Typography>
        </Stack>

        <Stack justifyContent={'center'} alignItems="center" direction="row" gap="5px">
          <TextField
            label="År"
            focused
            name="year"
            value={formData.year}
            fullWidth
            margin="normal"
            InputProps={disabledInput}
            variant="standard" />
          <TextField
            focused
            label="Kvartal"
            name="quarter"
            value={formData.quarter}
            fullWidth
            margin="normal"
            InputProps={disabledInput}
            variant="standard" />
        </Stack>
      </Stack>
      <Stack>
        <Stack direction="row" gap="5px">
          <CheckCircleOutlineRoundedIcon color={'success'}></CheckCircleOutlineRoundedIcon>
          <Typography variant="h5">
            Beskrivelse
          </Typography>
        </Stack>
        <Stack justifyContent={'center'} alignItems="center" direction="row" gap="5px">
          <TextField
            label="Kort beskrivelse av prosjektet"
            focused
            name="year"
            value={formData.description}
            fullWidth
            margin="normal"
            multiline
            InputProps={disabledInput}
            variant="standard" />
        </Stack>
        {formData.uploadFiles?.length && <>
          <Typography variant="body1">
            Filer
          </Typography>
          {Array.from(formData.uploadFiles).map(file => (
            <Typography variant="body2">
              {file.name}
            </Typography>
          ))}
        </>}
      </Stack>
    </Stack>
  </>);
};
