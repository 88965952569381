import { FC, useState } from 'react';
import { Stepper, Step, StepLabel, Container, Stack, AppBar } from '@mui/material';
import { Step1 } from './steps/Step1';
import { Step2 } from './steps/Step2';
import { Step6 } from './steps/Step6';
import { Step3 } from './steps/Step3';
import { Step5 } from './steps/Step5';
import { Step4 } from './steps/Step4';
import { FormProvider } from './FormContext';
import { Summary } from './steps/Summary';
import { Submit } from './steps/Submit';
import { ButtonBar } from './buttonbar';

const steps: string[] = [
  'Kontakt',
  'Virksomhet',
  'Behov',
  'Lokasjon',
  'Tidspunkt',
  'Beskrivelse',
  'Oppsummering'
];

const MultiStepForm: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formId, setFormId] = useState<string>("");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setActiveStep(activeStep + 1);
  };

  return (
    <FormProvider>
      <Stack paddingTop={5} >
        <Container maxWidth="xl">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} onClick={() => index < activeStep ? setActiveStep(index) : {}} >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Container>
        <Stack justifyContent={'center'} alignItems="center" direction="column" paddingTop={5} paddingBottom={"150px"}>
          <form onSubmit={onSubmit}>
            {activeStep === 0 && <Step1 />}
            {activeStep === 1 && <Step2 />}
            {activeStep === 2 && <Step3 />}
            {activeStep === 3 && <Step4 />}
            {activeStep === 4 && <Step5 />}
            {activeStep === 5 && <Step6 />}
            {activeStep === 6 && <Summary />}
            {activeStep === 7 && <Submit formId={formId} />}
            <>
              <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0, background: "white" }}>
                <ButtonBar activeStep={activeStep} setActiveStep={setActiveStep} formId={formId} setFormId={setFormId} />
              </AppBar>
            </>
          </form>
        </Stack>
      </ Stack >
    </FormProvider>
  );
};

export default MultiStepForm;
