import { Button, Stack, TextField } from '@mui/material';
import * as React from 'react';
import { useState, useContext } from 'react';
import { areaProvider } from '../../../../../services'
import { FormContext } from '../../../FormContext';

interface IAreaLocationLookup {
  name: string;
  onChange: Function;
};

type AreaLocation = {
  kommunenavn: string;
  gardsnummer: string;
  bruksnummer: string;
}

const queryURL = (params: AreaLocation) => (
  'https://ws.geonorge.no/adresser/v1/sok?fuzzy=false&' +
  new URLSearchParams(params).toString() +
  '&treffPerSide=10&side=0&asciiKompatibel=true'
)

export const AreaLocationLookup: React.FC<IAreaLocationLookup> = ({ onChange }) => {
  const { selectedLocationOption, setProvider } = useContext(FormContext);
  const [bnr, setBnr] = useState<number>();
  const [gnr, setGnr] = useState<number>();
  const [kommune, setKommune] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    const location = {
      kommunenavn: (kommune ?? selectedLocationOption?.kommunenavn) || "",
      gardsnummer: (gnr ?? selectedLocationOption?.gardsnummer) || "",
      bruksnummer: (bnr ?? selectedLocationOption?.gardsnummer) || ""
    }
    if (!location.kommunenavn || !location.gardsnummer) return
    setIsLoading(true);
    try {
      const response = await fetch(queryURL(location));
      let json = await response.json();
      const value = json.adresser[0];
      setProvider(areaProvider(value.representasjonspunkt));
      onChange(value);
    } catch (error) {
      // setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const keyAction = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      fetchData();
    }
  }

  return (
    <Stack>
      <Stack justifyContent={'center'} alignItems="center" direction="row" gap="5px">
        <TextField
          fullWidth
          value={(kommune ?? selectedLocationOption?.kommunenavn) || ""}
          onChange={(e) => setKommune(e.target.value)}
          onKeyDown={keyAction}
          margin="normal"
          variant="outlined"
          type={"text"}
          label="Kommunenavn">
        </TextField>
        <TextField
          value={(gnr ?? selectedLocationOption?.gardsnummer) || ""}
          onChange={(e) => setGnr(+e.target.value)}
          onKeyDown={keyAction}
          fullWidth
          margin="normal"
          variant="outlined"
          type={"number"}
          label="Gårdsnummer">
        </TextField>
        <TextField
          fullWidth
          value={(bnr ?? selectedLocationOption?.bruksnummer) || ""}
          onChange={(e) => setBnr(+e.target.value)}
          onKeyDown={keyAction}
          margin="normal"
          type={"number"}
          variant="outlined"
          label="Bruksnummer">
        </TextField>
      </Stack>
      <Button size='large' variant="contained" color="primary" onClick={fetchData} >
        Velg plassering
      </Button>
    </Stack>
  );
}