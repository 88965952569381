
import { createContext, FC, useState } from 'react';
import { SubmitStatus } from '../../services'
import { Provider, VoltageLevel, LocationType, IndustryType } from '../../types'

export interface ISelectedLOption {
  kommunenavn: string;
  nettselskap: Provider;
  adressetekst: string;
  postnummer: string;
  poststed: string;
  gardsnummer: string;
  bruksnummer: string;
  representasjonspunkt: {
    lat: number;
    lon: number;
  };
}

interface IFormData {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  orgName: string;
  orgNr: string;
  industryProduction: any;
  industryConsumption: any;
  location: any;
  provider: any;
  effectSize: any;
  estimatedProduction: any;
  estimatedConsumption: any;
  voltage: any;
  year: number | null;
  quarter: string;
  description: string;
  voltageLevel: VoltageLevel | null;
  locationType: LocationType;
  uploadFiles: FileList | null;
  submitStatus: SubmitStatus;
}

interface FormState {
  formData: IFormData | null;
  handleFormChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setOrganisation: (name: string, number: string) => void;
  selectedIndustryType: IndustryType;
  setSelectedIndustryType: (value: IndustryType) => void;
  locationType: LocationType;
  setLocationType: (value: number) => void;
  onChangeLocationType: (index: number) => void;
  setLocation: (value: any) => void;
  setProvider: (value: any) => void;
  selectedLocationOption: any;
  setUploadFiles: (files: FileList | null) => void;
  submitStatus: SubmitStatus;
  setSubmitStatus: (status: SubmitStatus) => void;
}

export const FormContext = createContext<FormState>({
  formData: null,
  handleFormChange: (event: React.ChangeEvent<HTMLInputElement>) => { },
  setOrganisation: (name: string, number: string) => { },
  selectedIndustryType: IndustryType.NONE,
  setSelectedIndustryType: (value: IndustryType) => { },
  locationType: LocationType.NONE,
  setLocationType: (value: number) => { },
  onChangeLocationType: (index: number) => { },
  setLocation: (value: any) => { },
  setProvider: (value: any) => { },
  selectedLocationOption: null,
  setUploadFiles: (files: FileList | null) => null,
  submitStatus: SubmitStatus.INIT,
  setSubmitStatus: (status: SubmitStatus) => { }
});

interface FormProviderProps {
    children: React.ReactNode;
}

export const FormProvider: FC<FormProviderProps> = ({ children }) => {
  const [selectedIndustryType, setSelectedIndustryType] = useState(IndustryType.NONE);
  const [locationType, setLocationType] = useState(0);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(SubmitStatus.INIT);
  const [formData, setFormData] = useState<IFormData>({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    orgName: "",
    orgNr: "",
    industryProduction: null,
    industryConsumption: null,
    location: {},
    provider: {},
    effectSize: null,
    estimatedProduction: null,
    estimatedConsumption: null,
    voltage: null,
    voltageLevel: null,
    year: null,
    quarter: "",
    description: "",
    locationType: LocationType.NONE,
    uploadFiles: null,
    submitStatus: SubmitStatus.INIT
  });

  function onChangeLocationType(index: number): void {
    // setSelectedOption(null);
    setLocationType(index);
  }

  const setLocation = (value: any) => {
    setSelectedOption(value);
    setFormData((prevFormValues) => ({
      ...prevFormValues,
      location: value,
    }));
  }

  const setProvider = (value: any) => {
    setFormData((prevFormValues) => ({
      ...prevFormValues,
      provider: value,
    }));
  }

  const formIsValid = (formData: IFormData): boolean => {
    // Need some solid validataion here...
    if (formData.email && formData.firstName && formData.orgNr && formData.description) return true
    return false
  }

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.name, event.target.value);
    setFormData((prevFormValues) => {
      const formData = {
        ...prevFormValues,
        [event.target.name]: event.target.value,  
      }
      setSubmitStatus(formIsValid(formData) ? SubmitStatus.READY : SubmitStatus.INIT);
      return (formData);
    });
  };

  const setOrganisation = (name: string, number: string) => {
    setFormData((prevFormValues) => ({
      ...prevFormValues,
      orgName: name,
      orgNr: number
    }));
  };

  const setUploadFiles = (files: FileList | null) => {
    setFormData((prevFormValues) => ({
      ...prevFormValues,
      uploadFiles: files
    }));
  };

  return (
    <FormContext.Provider value={{
      formData, handleFormChange,
      setOrganisation,
      selectedIndustryType, setSelectedIndustryType,
      locationType, setLocationType, 
      onChangeLocationType,
      setLocation,
      setProvider,
      selectedLocationOption: selectedOption,
      setUploadFiles,
      submitStatus,
      setSubmitStatus
    }}>
      {children}
    </FormContext.Provider>
  );
}