/* 
  This is a component that is used to create a form field for entering a company name.
  It uses the BRREG API to look up the company name based on the entered org number.
  If the org number is valid, the name will be displayed in the field.
*/

import { FC, useContext } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { BRREGLookup } from './BRREGLookup';
import FormHeader from '../../../FormHeader';
import { FormContext } from '../../FormContext';

type Step1Props = {
};

export const Step1: FC<Step1Props> = () => {
  const { formData, handleFormChange } = useContext(FormContext);
  return (<>
    <FormHeader header='Kontaktinformasjon' />
    <Stack paddingBottom="25px" sx={{minWidth: '400px'}}>
      <Typography variant="body1" paddingBottom="15px">
        Innmelder (firma)
      </Typography>
      <BRREGLookup />
    </Stack>
    <Stack>
      <Typography variant="body1">
        Kontaktperson
      </Typography>
      <Typography variant="body1" style={{ color: 'gray' }}>
        Hvem er deres kontaktperson?
      </Typography>
      <TextField
        label="E-post"
        name="email"
        value={formData?.email}
        onChange={handleFormChange}
        fullWidth
        margin="normal"
        variant="outlined"
        type="email"
        required />
      <Stack justifyContent='center' alignItems="center" direction="row" gap="5px">
        <TextField
          label="Fornavn"
          name="firstName"
          value={formData?.firstName}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
          variant="outlined"
          type="text"
          required />
        <TextField
          label="Etternavn"
          name="lastName"
          value={formData?.lastName}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
          variant="outlined"
          type="text"
          required />

      </Stack>
      <TextField
        label="Telefon"
        name="phone"
        value={formData?.phone}
        onChange={handleFormChange}
        fullWidth
        margin="normal"
        variant="outlined"
        type="tel"
        required />
    </Stack>
  </>);
};
