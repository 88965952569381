import { FC, useState } from 'react';
import Header from './components/Header';
import MultiStepForm from './components/MultiStepForm';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import StartScreen from './components/StartScreen';
import { ThemeProvider } from '@mui/material';
import { muiTheme } from './theme';
import ConsentScreen from './components/ConsentScreen';
import SuccessScreen from './components/SuccessScreen';

const App: FC = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <Header title="Nettilknytning" />
      <Router>
        <Routes>
          <Route path="/" element={
            <StartScreen onStart={() => window.location.hash = '/consent'} />
          }>
          </Route>
          <Route path="/consent" element={
            <ConsentScreen onStart={() => window.location.hash = '/form'} />
          }>
          </Route>
          <Route path="/form" element={
            <MultiStepForm />
          }>

          </Route>
          <Route path="/success" element={
            <SuccessScreen onStart={() => window.location.hash = '/'}></SuccessScreen>
          }></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
