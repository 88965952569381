import * as React from 'react';
import { FC, useContext } from 'react';
import { FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import FormHeader from '../../../FormHeader';
import { handleInvalidRadioButtons, handleInvalidTextField } from '../../validation';
import { FormContext } from '../../FormContext';
import { VoltageLevel, LowVoltages } from '../../../../types'

type Step3Props = {};

export const Step3: FC<Step3Props> = () => {
  const { formData, selectedIndustryType, handleFormChange } = useContext(FormContext);
  return (<>
    <FormHeader header='Tekniske detaljer' subHeader='Fyll inn teknisk informasjon om prosjektet.' />
    <Stack gap={2}>
      <Stack >
        <TextField required fullWidth
          label="Max effektstørrelse"
          type='number'
          name='effectSize'
          onChange={handleFormChange}
          value={formData?.effectSize || ""}
          margin="normal"
          variant="outlined"
          InputProps={{ startAdornment: <InputAdornment position="start">MW</InputAdornment> }}
        />
      </Stack>
      <Stack>
        <Typography variant="body1" color="gray">
          Velg ønsket spenningsnivå
        </Typography>
        <FormControl required>
          <RadioGroup
            aria-labelledby="-radio-buttons-group-voltage-level"
            name="voltageLevel"
            value={formData?.voltageLevel}
            onChange={handleFormChange}
            defaultChecked={false}>
            <FormControlLabel className='radio-form-voltage' value={VoltageLevel.LOW} control={<Radio required />} label="Lavspent" />
            <FormControlLabel className='radio-form-voltage' value={VoltageLevel.HIGH} control={<Radio required />} label="Høyspent" />
            <FormControlLabel className='radio-form-voltage' value={VoltageLevel.REGIONAL} control={<Radio required />} label="Regionalt" />
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack>
        {formData?.voltageLevel === VoltageLevel.LOW && <FormControl >
          <InputLabel id="label-voltagetype">Ønsket spenningsnivå</InputLabel>
          <Select required fullWidth
            label="Ønsket spenningsnivå"
            labelId="label-voltagetype"
            id="voltage"
            name='voltage'
            onChange={(e: any) => handleFormChange(e)}
            value={formData?.voltage || ""}
            startAdornment={<InputAdornment position="start">kV</InputAdornment>}
          >
            {LowVoltages.map((voltage: number) => <MenuItem value={voltage} key={voltage}>{voltage.toFixed(2)}</MenuItem>)}
          </Select>
        </FormControl>
        }
      </Stack>
      {(selectedIndustryType === 1 || selectedIndustryType === 3) && <Stack>
        <TextField fullWidth
          label="Estimert energiproduksjon"
          type='number'
          name='estimatedProduction'
          value={formData?.estimatedProduction || ""}
          onChange={handleFormChange}
          margin="normal"
          variant="outlined"
          InputProps={{ startAdornment: <InputAdornment position="start">GWh</InputAdornment> }}
        />
      </Stack>}
      {(selectedIndustryType === 2 || selectedIndustryType === 3) && <Stack>
        <TextField fullWidth
          label="Estimert energiforbruk"
          type='number'
          name='estimatedConsumption'
          value={formData?.estimatedConsumption || ""}
          onChange={handleFormChange}
          margin="normal"
          variant="outlined"
          InputProps={{ startAdornment: <InputAdornment position="start">GWh</InputAdornment> }}
        />
      </Stack>}
    </Stack>
  </>);
};
