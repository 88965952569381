import { FC, ChangeEventHandler, useState, useContext } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import FormHeader from '../../../FormHeader';
import { FormContext } from '../../FormContext';

type Step6Props = {
};

type UploadProps = {
  select: ChangeEventHandler<HTMLInputElement>
};

const UploadButton: FC<UploadProps> = ({select}) => {
  return (<>
    <Button size='large' variant="contained" color="primary">
      <label htmlFor='upload_button'>
        Velg filer
      </label>
      <input id='upload_button' type='file' multiple onChange={select} style={{display: 'none'}} />
    </Button>
  </>)
};

export const Step6: FC<Step6Props> = () => {
  const { formData, handleFormChange, setUploadFiles } = useContext(FormContext);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(formData?.uploadFiles || null);

  const selectFiles: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.target;
    setSelectedFiles(e.target.files);
    setUploadFiles(e.target.files)
  };
  console.log("SelectedFiles", selectedFiles, formData)
  const descriptionCount = formData?.description.length !== 0 ? `(${formData?.description.length} av 4000)` : '';
  
  return (<>
    <FormHeader header='Gi en kort beskrivelse av prosjektet' subHeader=''></FormHeader>
    <TextField
      label={`Gi en overordnet beskrivelse av prosjektet ${descriptionCount}`}
      name="description"
      value={formData?.description}
      onChange={handleFormChange}
      fullWidth
      margin="normal"
      variant="outlined"
      type="description"
      required={true}
      inputProps={{ maxLength: 4000 }}
      rows={8}
      multiline />
    <Stack gap="5px">
      <Typography variant="body1">
        Last opp ett eller flere vedlegg
      </Typography>
    </Stack>
    <UploadButton select={selectFiles} />
    {formData?.uploadFiles?.length && <>
      {Array.from(formData.uploadFiles).map(file => (
        <Typography variant="body2">
          {file.name}
        </Typography>
      ))}
    </>}
</>);
};
