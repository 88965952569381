import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

type FormHeaderProps = {
    header: string;
    subHeader?: string;
};

const FormHeader: FC<FormHeaderProps> = ({ header, subHeader: subheader }) => {
    return (
        <>
            <Stack direction="column" alignItems='center' gap={"10"} paddingBottom="50px">
                <Typography variant="h4" gutterBottom>
                    {header}
                </Typography>
                {subheader &&
                    <Typography variant="body1" gutterBottom>
                        {subheader}
                    </Typography>
                }
            </Stack>
        </>
    );
};

export default FormHeader;